@font-face{
    font-family: 'cuprumregular';
    src: url('fonts/cuprum-regular-webfont-webfont.eot');
    src: url('fonts/cuprum-regular-webfont-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/cuprum-regular-webfont-webfont.woff') format('woff'),
         url('fonts/cuprum-regular-webfont-webfont.ttf') format('truetype'),
         url('fonts/cuprum-regular-webfont-webfont.svg#_.regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family:'ProximaNova';
	src: url('fonts/ProximaNova-Light_gdi.eot');
	src: url('fonts/ProximaNova-Light_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/ProximaNova-Light_gdi.woff') format('woff'),
		url('fonts/ProximaNova-Light_gdi.ttf') format('truetype'),
		url('fonts/ProximaNova-Light_gdi.svg#ProximaNova-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2666;
}
@font-face {
	font-family:'ProximaNova';
	src: url('fonts/ProximaNova-Regular_gdi.eot');
	src: url('fonts/ProximaNova-Regular_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/ProximaNova-Regular_gdi.woff') format('woff'),
		url('fonts/ProximaNova-Regular_gdi.ttf') format('truetype'),
		url('fonts/ProximaNova-Regular_gdi.svg#ProximaNova-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2666;
}
@font-face {
	font-family:'ProximaNova';
	src: url('fonts/ProximaNova-Bold_gdi.eot');
	src: url('fonts/ProximaNova-Bold_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/ProximaNova-Bold_gdi.woff') format('woff'),
		url('fonts/ProximaNova-Bold_gdi.ttf') format('truetype'),
		url('fonts/ProximaNova-Bold_gdi.svg#ProximaNova-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2666;
}
.grippie,
.links { 
	display: none !important; 
}
ul.menu li {
	margin: 0;
	padding: 0;
	list-style: none;
}
input.form-submit {
	cursor: pointer;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
input.form-submit:hover{
	opacity: 0.8;
}
.jcarousel-clip{
	overflow: hidden;
}
input{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
input.error,
textarea.error,
input[type="text"].error,
input[type="password"].error{
	border:1px solid red !important;
}
.jGrowl-notification.error {
    background-color: #CC0000 !important;
}
.jGrowl-notification.status {
    background-color: #009900 !important;
}
#jGrowl .jGrowl-notification {
    background-position: 4px 8px !important;
}
.form-item, .form-actions {
	margin: 0;
}
/*variables*/
$main_color:#514e4e;
$green:#02ae5f;
/*variables*/
// mixins
@mixin btn{
	color: $green;
		padding: 5px 15px;
		display: inline-block;
		border: 2px solid $green;
		text-transform: uppercase;
		font-weight: 700;
		cursor: pointer;
		transition: 0.3s;
		&:hover{
			background: $green;
			color: #fff;
		}
}
@mixin shadow{
	&::before{
		content: '';
		display: block;
		width: 100%;
		background: url(images/middle_shadow.png) no-repeat  scroll 0 0 transparent;
		height: 13px;
		position: absolute;
		bottom: -13px;
		left: 0;
	}
}
@mixin front_title{
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	padding-bottom: 15px;
	position: relative;
	&::before{
		content: '';
		display: block;
		width: 5px;
		height: 34px;
		background: $green;
		position: absolute;
		top: -4px;
		left: -30px;
	}
}
// mixins
a {
	color: $main_color;
	outline: none;
	text-decoration: underline;   
}
a:hover {
	text-decoration: none;
}
tbody,
img {
	border: none;
}
input {
	vertical-align: middle;
}
img{
	max-width: 100%;
	height: auto;
}
* {
	margin: 0;
	padding: 0;
	outline: none;
}
p {
	margin: 0 0 18px;
}
body.admin-menu {
	margin-top: 0 !important;
	padding-top: 32px;
}
html {
	height: 100%;
}
body {
	font: 400 14px 'ProximaNova', Arial, sans-serif;
	width: 100%;
	height: 100%;
	line-height: 1.3125;
	color: $main_color;
    background: url(images/main_bg.jpg) no-repeat fixed center 0 transparent;
}
.wrapper {
	width: 100%; 
	margin: 0 auto;
	min-height: 100%;
	height: auto !important; 
	height: 100%;
}
/* Header
-----------------------------------------------------------------------------*/
.header{
	padding-top: 30px;
}
.header_wrap{
	max-width: 1170px;
	margin:0 auto;
	overflow: hidden;
	padding: 10px 30px 0;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	#block-drupal-package-block-header{
		overflow: hidden;
	}
	.logo_floater{
		float: left;
		padding-bottom: 15px;
	}
	.field_inner_wrap_field_slogan{
		display: table;
		margin: 0 auto;
		max-width: 275px;
		text-align: center;
		font-size: 24px;
		padding-top: 5px;
		line-height: 1.2;
	}
	.phone_wrap{
		float: right;
		padding-top: 5px;
		.field_inner_wrap_field_email{
			float: right;
			padding-bottom: 15px;
			.field-item a{
				color: $green;
			}
		}
		.field_inner_wrap_field_header_phone{
			clear: both;
			float: right;
			.field-item {
				float: left;
				a{
					font-size: 18px;
					text-decoration: none;
					font-weight: 700;
					display: block;
					padding-left: 50px;
					background: url(images/phone_icon.png) no-repeat  scroll 25px center transparent;
				}
			}
		}
	}
}
#block-system-main-menu{
	clear: both;
	background: #384452;
	margin-right: -30px;
	margin-left: -30px;
	.menu{
		width: 100%;
		display: table;
		li{
			display: table-cell;
			text-align: center;
			padding-right: 1px;
			background: url(images/menu_line.png) no-repeat  scroll right center transparent;
			a{
				font-weight: 700;
				font-size: 16px;
				text-decoration: none;
				font-weight: 700;
				color: #fff;	
				display: block;
				padding: 14px 0;
				text-transform: uppercase;
				border-bottom: 4px solid #323c49;
				letter-spacing: 1px;
				&:hover,&.active{
					background: #00a650;
					border-color: #009247;
				}
			}
		}
	}
}

/* Middle
-----------------------------------------------------------------------------*/
.middle {
	width: 100%;
	padding: 0 0 139px;
	position: relative;
	max-width: 1170px;
	margin: 0 auto;
	clear: both;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 30px;
	margin-bottom: 30px;
	@include shadow;
	&::after {
		display: table;
		clear: both;
		content: '';
	}
}
.container {
	width: 100%;
	float: left;
	overflow: hidden;
}
#content {
	padding: 0 ;
}
.page_title{
	font-size: 36px;
	margin: 0;
	line-height: 1;
	padding: 15px 30px 25px;
	margin-bottom: 25px;
	border-bottom: 1px solid #e5e5e5;
	margin-right: -30px;
	margin-left: -30px;
	font-weight: 300;
}
.my_bread{
	text-transform: uppercase;
	color: #919aa4;
	font-size: 11px;
	// padding-left: 30px;
	a{
		text-transform: uppercase;
		color: #919aa4;
		font-size: 11px;
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}
	.arr{
		display: inline-block;
		padding: 0 5px;
	}
}
/*articles*/
.view-article{
	&.view-display-id-page_1, &.view-display-id-block_1,&.view-display-id-page_2{
		.group_item{
			float: left;
			width: 100%;
			padding-bottom: 30px;
			margin-bottom: 30px;
			border-bottom: 1px solid #e5e5e5;
		}
		.views-row{
			width: 22.9%;
			float: left;
			margin-right: 2.8%;
			&:nth-child(4){
				float: right;
				margin-right: 0;
			}
			.views-field-field-image{
				margin-bottom: 10px;
			}
			.views-field-title{
				padding-bottom: 15px;
				margin-bottom: 10px;
				position: relative;
				a{
					color: $green;
					height: 36px;
					overflow: hidden;
					display: block;
				}
				&::after{
					content: '';
					display: block;
					width: 44px;
					height: 1px;
					background: #ccc;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
			.views-field-created{
				color: #8696a7;
				padding-bottom: 5px;
			}
		}
	}
}
#block-views-article-block-1{
	padding-bottom: 20px;
	overflow: hidden;
	.block_title{
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 700;
		padding: 15px 0 20px;
		border-top: 1px solid #e5e5e5;
		text-align: center;
	}
}
.node-article,.node-news{
	.node_title{
		font-size: 24px;
		padding-bottom: 15px;
		font-weight: 700;
		padding-bottom: 15px;
		text-align: center;
	}
	.field-name-body{
		p{
			text-align: justify;
			line-height: 1.4;
		}
	}
	.node_date{
		color: #8696a7;
	}
}
#node-12{
	.field-name-body{
		p{
			text-align: justify;
			line-height: 1.4;
		}
	}
	iframe{
		width: 560px;
		height: 315px;
		float: right;
		padding: 15px 0 15px 15px;
	}
}
#block-drupal-package-block-about{
	line-height: 1.4;
	.field_inner_wrap_field_about_coll{
		float: left;
		width: 697px;
		margin-right: 15px;
		position: relative;
	}
	.field_inner_wrap_field_description{
		text-align: justify;
	}
	.field-name-field-adout-body{
		position: absolute;
		width: 490px;
		height: 180px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding: 20px 40px 10px 30px;
		background: rgba(0,0,0,.8);
		top: 30px;
		left: 30px;
		color: #fff;
		font-size: 20px;
		font-weight: 300;
	}
	.slick-dots{
		position: absolute;
		top: 170px;
		left: 90px;
		z-index: 10;
		li{
			float: left;
			margin-right: 10px;
			list-style: none;
			&.slick-active{
				button{
					background: #fff;
				}
			}
			button{
				border: none;
				background: #3f4140;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				text-indent: -9999px;
			}
		}
	}
}
// articles
// gallery
.node-gallery{
	.images_wrap{
		width: 877px;
		margin: 0 auto;
		.images_max{
			height: 566px;
			iframe{
				height: 566px;
				border: none;
				width: 100%;
			}
		}
		.images_min{
			width: 795px;
			margin: 0 auto;
			margin-top: 15px;
			.photo,.video_wrap{
				margin: 0 10px;
			}
			.iframe_img{
				height: 73px;
				max-width: none;
				margin: 0 auto;
				width: 113px;
				opacity: .45;
			}
			.video_wrap{
				background: url(images/play.png) no-repeat  scroll center #000;
			}
			button{
				text-indent: -9999px;
				border: none;
				position: absolute;
				top: 50%;
				margin-top: -12.5px;
				width: 23px;
				height: 23px;
				z-index: 1;
				&.slick-prev{
					left: -40px;
					background: url(images/slider_arrows_hover.png) no-repeat  scroll 0 0 transparent;
					&.slick-disabled{
						background: url(images/slider_arrows.png) no-repeat  scroll 0 0 transparent;
					}
				}
				&.slick-next{
					right: -40px;
					background: url(images/slider_arrows_hover.png) no-repeat  scroll right 0 transparent;
					&.slick-disabled{
						background: url(images/slider_arrows.png) no-repeat  scroll right 0 transparent;
					}
				}
			}
		}
	}
}
// gallery
// products
#block-block-2{
	width: 290px;
	background: #fcfcfc;
	float: left;
	border-right: 1px solid #ccc;
	.block_content{
		.term__name{
			position: relative;
			.field_content{
				a{
					display: block;
					padding: 25px 25px 25px 90px;
					text-decoration: none;
					font-weight: 700;
				}
			}
			img{
				position: absolute;
				top: 50%;
				transform:translateY(-50%);
				left: 30px;
			}
		}
		.child_wrap{
			.child_name{
				display: block;
				padding: 15px 25px 15px 105px;
				&.child_text{
					padding-left: 90px;
					cursor: pointer;
				}
			}
			a{
				text-decoration: none;
				&.active,&:hover{
					color: $green;
					text-decoration: underline;
				}
			}
		}
		.children__1 a{
			padding-left: 105px;
		}
		.term__wrap{
			.term__content{
				display: none;
			}
			&.active{
				background: #fff;
				border-top: 1px solid #ccc;
				border-bottom: 1px solid #ccc;
				position: relative;
				&.term__wrap-1{
					border-top: none;
				}
				&::after{
					position: absolute;
					content: '';
					display: block;
					height: 100%;
					width: 1px;
					background: #fff;
					right: -1px;
					top: 0;
				}
				&::before{
					content: '';
					display: block;
					height: 100%;
					width: 5px;
					position: absolute;
					top: 0;
					left: 0;
					background: $green;
				}
				.term__content{
					display: block;
				}
			}
			.child_name_content{
				display: none;
			}
			.child_name-wrap.active{
				.child_name_content{
					display: block;
				}
			}
		}
	}
}
.page-taxonomy-term,.node-type-products {
	#block-system-main{
		float: right;
		width: 820px;
	}
	.middle{
		padding-left: 0;
		padding-bottom: 0;
	}
	.page_title{
		padding-left: 60px;
		margin-bottom: 0;
	}
}
.view-products-tax.view-display-id-block_1{
	.views-field-name{
		font-size: 22px;
		font-weight: 700;
		padding: 25px 0;
		text-transform: uppercase;
	}
	.views-field-field-products-img{
		float: left;
		margin-right: 30px;
	}
	.views-field-description{
		text-align: justify;
	}
}
.view-products{
	padding-top: 30px;
	&.view-display-id-block_1,&.view-display-id-block_2,&.view-display-id-block_3{
		clear: both;
		.group_item{
			float: left;
			width: 100%;
			padding-bottom: 35px;
		}
		.views-row{
			float: left;
			width: 33.3%;
			text-align: center;
			.views-field-title a{
				display: inline-block;
				text-align: left;
				font-weight: 700;
				&:hover{
					color: $green;
				}
			}
			.views-field-field-products-image{
				text-align: center;
				.field-content{
					display: table;
					height: 140px;
					text-align: center;
					width: 100%;
					a{
						display: table-cell;
						vertical-align: middle;
					}
				}
			}
		}
	}
}
.node-products{
	a.come_back{
		display: block;
		padding-left: 23px;
		background: url(images/come_back.png) no-repeat  scroll 0 center transparent;
		margin-top: 15px;
		margin-bottom: 5px;
	}
	.node_title{
		font-size: 22px;
		font-weight: 700;
		padding: 15px 0;
	}
	.images_wrap{
		width: 540px;
		float: left;
		margin-right: 30px;
		button{
			text-indent: -9999px;
			border: none;
			position: absolute;
			top: 50%;
			margin-top: -8px;
			width: 28px;
			height: 16px;
			z-index: 1;
			transition: 0.3s;
			&.slick-prev{
				left: -40px;
				background: url(images/slick_arrows2.png) no-repeat  scroll 0 0 transparent;
			}
			&.slick-next{
				right: -40px;
				background: url(images/slick_arrows2.png) no-repeat  scroll right 0 transparent;
			}
			&:hover{
				opacity: 0.75;
			}
		}
		.images_wrap-min{
			width: 310px;
			margin: 20px auto 30px;
			.image{
				margin: 0 10px;
				height: 86px;
				border: 1px solid #e5e5e5;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				.image_wrap{
					display: table;
					width: 100%;
					height: 100%;
					.field{
						vertical-align: middle;
						display: table-cell;
						img{
							margin: 0 auto;
						}
					}
				}
				&.slick-active.slick-current{
					border-bottom: 5px solid $green;
				}
			}
		}
		.images_wrap-max{
			img{
				margin: 0 auto;
			}
		}
	}
	.field-name-body{
		overflow: hidden;
		.field-label{
			text-transform: uppercase;
			padding-bottom: 10px;
			margin-bottom: 10px;
			position: relative;
			&::after{
				content: '';
				display: block;
				width: 50px;
				height: 1px;
				background: #ccc;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}
	.order_btn{
		@include btn;
		text-decoration: none;
	}
	.field-name-field-products-coll{
		clear: both;
		margin-top: 30px;
		padding-top: 20px;
		border-top: 1px solid #e5e5e5;
		.field-label{
			text-transform: uppercase;
			width: 280px;
			float: left;
			font-size: 16px;
		}
		.field-label+.field-items{
			line-height: 32px;
			overflow: hidden;
			.field-name-field-products-coll-name{
				float: left;
				background: #fff;
				padding-right: 10px;
				position: relative;
				z-index: 10;
			}
			.field-name-field-products-coll-val{
				float: right;
				background: #fff;
				padding-left: 10px;
				position: relative;
				z-index: 10;
			}
			&>.field-item{
				position: relative;
				&:hover{
					font-weight: 700;
					cursor: pointer;
				}
				&::after{
					content: '';
					display: block;
					width: 100%;
					height: 1px;
					background: #e5e5e5;
					position: absolute;
					top: 50%;
					margin-top: -0.5px;
					z-index: 0;
				}
			}
		}
	}
	.form_block{
		clear: both;
		padding-top: 15px;
		margin-top: 15px;
		overflow: hidden;
		border-top: 1px solid #e5e5e5;
		.package_block{
			float: left;
			width: 280px;
			.field_inner_wrap_field_products_coll_name{
				font-size: 16px;
				font-weight: 700;
				padding-bottom: 10px;
				text-transform: uppercase;
			}
		}
		form{
			width: 510px;
			float: right;
			padding-top: 5px;
			margin-bottom: 25px;
			.form-required{
				display: none;
			}
			label{
				font-weight: 400;
				float: left;
				width: 140px;
				text-align: right;
				line-height: 21px;
			}
			.form-item{
				width: 100%;
				float: left;
				padding-bottom: 10px;
				.form-text{
					width: 195px;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					padding: 0 10px;
					height: 21px;
				    font: 400 14px 'ProximaNova', Arial, sans-serif;
				    color: $main_color;
				}
				textarea{
					width: 356px;
					height: 80px;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					padding: 10px;
					font: 400 14px 'ProximaNova', Arial, sans-serif;
				    color: $main_color;
				    resize:none;
				    float: left;
				    margin-left: 14px;
				}
			}
			.form-submit{
				@include btn;
				background: none;
				margin-left: 155px;
			}
		}
	}
	.products_block2{
		overflow: hidden;
		padding: 20px 0;
		border-top: 1px solid #e5e5e5;
		.block_title{
			font-size: 16px;
			font-weight: 700;
			padding-bottom: 10px;
			text-transform: uppercase;
		}
		.view-products{
			padding-top: 0;
		}
	}
}
// products
// documents
.view-documents{
	&.view-display-id-page_1{
		.group_item{
			float: left;
			width: 100%;
			padding-bottom: 30px;
			margin-bottom: 30px;
			border-bottom: 1px solid #e5e5e5;
		}
		.views_row{
			float: left;
			width: 33%;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			
			position: relative;
			.file_type{
				float: left;
				width: 36px;
				height: 41px;
				background: url(images/document.png) no-repeat  scroll 0 0 transparent;
				margin-right: 10px;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				padding-top: 19px;
				text-align: center;
				font-size: 10px;
				text-transform: uppercase;
			}
			.title_wrap{
				overflow: hidden;
			}
			.title{
				padding-bottom: 10px;
			}
			.file_size{
				color: #8696a7;
				padding-bottom: 10px;
			}
			.download{
				a{
					display: inline-block;
					padding: 5px 10px 5px 35px;
					background: url(images/download.png) no-repeat  scroll 5px center transparent;
					color: $green;
					&:hover{
						text-decoration: none;
						color: #fff;
						background: url(images/download_hover.png) no-repeat  scroll 5px center $green;
					}
				}
			}
		}
	}
}
// documents
// Contacts
#block-drupal-package-block-contacts{
	overflow: hidden;
	.block_wrap{
		width: 825px;
		float: left;
		&>.field_inner_wrap:not(:first-child){
			float: left;
			width: 33.3%;
			.field-label{
				font-size: 16px;
				text-transform: uppercase;
				padding-bottom: 10px;
			}
			.field-items{
				a{
					text-decoration: none;
				}
			}
		}
		iframe{
			width: 100%;
			border: none;
			height: 250px;
			margin-bottom: 15px;
		}
		.field_inner_wrap_field_email .field-items a{
			text-decoration: underline !important;
			color: $green;
			&:hover{
				text-decoration: none !important;
			}
		}
	}
	.form_wrap{
		width: 255px;
		float: right;
		form{
			width: 100%;
			.form-text{
				width: 100%;
				height: 21px;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				font: 400 14px 'ProximaNova', Arial, sans-serif;
			    color: $main_color;
			    margin-bottom: 10px;
			    padding: 0 10px;
			}
			textarea{
				width: 100%;
				resize:none;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				font: 400 14px 'ProximaNova', Arial, sans-serif;
			    color: $main_color;
			    height: 135px;
			    margin-bottom: 10px;
			    padding: 10px;
			}
			label{
				display: none;
			}
			.form-submit{
				@include btn;
				background: none;
			}
		}
	}
	.field_inner_wrap_field_form_title{
		font-size: 16px;
		font-weight: 700;
		padding-bottom: 5px;
		text-transform: uppercase;
	}
}
// Contacts
// Front
//slider_front
.front{
	.middle{
		padding: 0;
		background: none;
		padding-bottom: 0;
		&::after{
			display: none;
		}
	}
	.container{
		overflow: visible;
	}
}
#block-drupal-package-block-slider-front{
	height: 339px;
	background: url(images/slide_bg.png) no-repeat  scroll 0 0 transparent;
	.block_content{
		padding-left: 30px;
		.field-name-field-form-title{
			padding-top: 55px;
			font-size: 32px;
			text-transform: uppercase;
			font-weight: 700;
			color: #fff;
			position: relative;
			padding-bottom: 10px;
			margin-bottom: 15px;
			max-width: 440px;
			&::after{
				content: '';
				display: block;
				width: 130px;
				position: absolute;
				bottom: 0;
				left: 0;
				height: 2px;
				background: #fff;
			}
		}
		.field-name-field-image{
			float: right;
			margin: 35px 100px 0 0;
		}
		.field-name-field-description{
			font-size: 16px;
			max-width: 390px;
			padding-bottom: 10px;
			color: #fff;
			p{
				margin-bottom: 0;
			}
		}
		.field-name-field-link{
			a{
				@include btn;
				border-color: #fff;
				color: #fff;
				text-decoration: none;
				&:hover{
					background: #fff;
					color: $main_color;
				}
			}
		}
		.slick-dots{
			position: absolute;
			bottom: 0;
			left: 0;
			li{
				float: left;
				margin-right: 10px;
				list-style: none;
				button{
					width: 14px;
					height: 14px;
					border-radius: 50%;
					border: none;
					text-indent: -9999px;
					background: #eceff1;
					opacity: 0.2;
				}
				&.slick-active,&:hover{
					button{
						opacity: 1;
					}
				}
			}
		}
	}
}
//slider_front
//Category Block
#block-views-products-tax-block-2{
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 30px;
	margin-bottom: 30px;
	position: relative;
	float: left;
	width: 100%;
	@include shadow;
	.group_item:not(:last-child){
		float: left;
		width: 100%;
		border-bottom: 1px solid #e5e5e5;
	}
	.views-row{
		float: left;
		width: 16.6%;
		text-align: center;
		position: relative;
		padding: 20px 12px;
		height: 124px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		.views-field-field-products-icon{
			height: 38px;
			margin-bottom: 10px;
			.field-content{
				display: table;
				text-align: center;
				height: 100%;
				width: 100%;
				a{
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
		.views-field-name{
			a{
				font-weight: 700;
				text-decoration: none;
			}
		}
		.views-field-tid a{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			display: block;
			text-indent: -9999px;
			left: 0;
		}
		&:hover{
			background: #e4fbef;
		}
	}
}
//Category Block
// products block
#block-drupal-package-block-our-products{
	padding: 30px 30px 0 30px;
	background: #fff;
	clear: both;
	margin-bottom: 30px;
	position: relative;
	@include shadow;
	.block_wrap{
		width: 225px;
		float: left;
		margin-right: 30px;
		.field_inner_wrap_field_form_title{
			@include front_title;
		}
	}
	.field_inner_wrap_products{
		overflow: hidden;
		.view-products.view-display-id-block_3{
			padding-top: 0;
		}
	}
	.field_inner_wrap_field_link .field-item a{
		@include btn;
		text-decoration: none;
	}
}
#block-drupal-package-block-about-front{
	padding: 0 0 0 30px;
	background: #fff;
	clear: both;
	margin-bottom: 30px;
	float: left;
	position: relative;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	@include shadow;
	.field_inner_wrap_field_form_title{
		@include front_title;
		padding-top: 30px;
	}
	.field_inner_wrap_field_about_front{
		width: 550px;
		float: right;
		margin-left: 30px;
	}
	.field_inner_wrap_field_description{
		p:first-child{
			font-size: 20px;
			font-weight: 300;
		}
		p:last-child{
			margin-bottom: 10px;
			line-height: 1.4;
		}
	}
	.field_inner_wrap_field_link{
		.field-item{
			margin-bottom: 25px;
			a{
				color: $green;
			}
		}
	}
	.field_inner_wrap_field_gallery_video{
		overflow: hidden;
		iframe{
			display: block;
		}
	}
	.field-name-field-adout-body {
	    position: absolute;
	    width: 490px;
	    height: 180px;
	    -webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	    padding: 20px 40px 10px 30px;
	    background: rgba(0, 0, 0, 0.8);
	    top: 30px;
	    left: 30px;
	    color: #fff;
	    font-size: 20px;
	    font-weight: 300;
	}
	.slick-dots{
		position: absolute;
		top: 170px;
		left: 90px;
		z-index: 10;
		li{
			float: left;
			margin-right: 10px;
			list-style: none;
			&.slick-active{
				button{
					background: #fff;
				}
			}
			button{
				border: none;
				background: #3f4140;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				text-indent: -9999px;
			}
		}
	}

}
// products block
//block article
#block-views-article-block-2{
	padding: 30px;
	background: #fff;
	clear: both;
	margin-bottom: 30px;
	position: relative;
	float: left;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 860px;
	@include shadow;
	&::before{
		background-size: contain;
	}
	.field_inner_wrap_field_form_title{
		@include front_title;
		float: left;
	}
	.field_inner_wrap_field_link{
		float: right;
		line-height: 26px;
		a{
			color: #8696a7;
		}
	}
	.view-content{
		clear: both;
		.views-row{
			float: left;
			width: 30.6%;
			margin-right: 4.1%;
			&:nth-child(3){
				margin-right: 0;
				float: right;
			}
			.views-field-field-image{
				margin-bottom: 10px;
			}
			.views-field-title{
				padding-bottom: 15px;
				margin-bottom: 10px;
				position: relative;
				a{
					color: $green;
					height: 36px;
					overflow: hidden;
					display: block;
				}
				&::after{
					content: '';
					display: block;
					width: 44px;
					height: 1px;
					background: #ccc;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
		}
	}
}
//block article
// block news
#block-views-article-block-3{
	padding: 23px 30px;
	background: #fff;
	margin-bottom: 30px;
	float: right;
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 285px;
	@include shadow;
	&::before{
		background-size: contain;
	}
	.field_inner_wrap_field_form_title{
		@include front_title;
		float: left;
	}
	.field_inner_wrap_field_link{
		float: right;
		line-height: 26px;
		a{
			color: #8696a7;
		}
	}
	.view-content{
		clear: both;
		.views-row:not(:last-child){
			float: left;
			width: 100%;
			padding-bottom: 20px;
		}
		.views-field-created{
			float: left;
			padding-right: 15px;
			color: #8696a7;
			.day{
				font-size: 20px;
				font-weight: 700;
			}
			.mounth{
				text-transform: uppercase;
			}
		}
		.views-field-title{
			line-height: 1.5;
		}
	}

}
// block news
// Front
/* Footer
-----------------------------------------------------------------------------*/
.footer {
	width: 100%;
	margin: 0 auto 0;
	height: 139px;
	position: relative;
	clear: both;
	margin-bottom: 30px;
	.foot_wrap{
		height: 100%;
		max-width: 1170px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding: 25px 30px 25px;
		margin:0 auto;
		background: url(images/footer_bg.jpg) no-repeat  scroll 0 0 transparent;
	}
	.logo_floater{
		float: left;
	}
	.block-system-footer-menu{
		display: table;
		margin: 0 auto;
		width: 440px;
		.block_content{
			padding-bottom: 10px;
			overflow: hidden;
		}
		.group_item{
			float: left;
			padding-right: 54px;
			&:last-child{
				float: right;
				padding-right: 0;
			}
			a{
				color: #fff;
				display: block;
				padding-bottom: 8px;
			}
		}
		.field_inner_wrap_field_footer_mail{
			color: #fff;
			a{
				color: #fff;
			}
		}
	}
	.field_inner_wrap_field_footer_phones{
		float: right;
		.field-item {
				float: left;
				a{
					font-size: 18px;
					text-decoration: none;
					font-weight: 700;
					display: block;
					padding-left: 50px;
					background: url(images/phone_icon_2.png) no-repeat  scroll 25px center transparent;
					color: #fff;
				}
			}
	}
	.logo_text{
		color: #fff;
		padding-left: 55px;
	}
}
.pager {
	position: relative;
	width: 260px;
	display: table;
	margin: 0 auto 0 !important;
}
.pager li a {
	font-weight: 700;
	font-size: 18px;
	color: #8696a7;
	text-decoration: none;
}
.pager li a:hover {
	color: $green;
}
.pager li.pager-next a,
.pager li.pager-previous a {
	display: inline-block;
	width: 23px;
	height: 23px;
	text-indent: -9999px;
	position: absolute;
	top: 50%;
	margin-top: -11.5px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.item-list .pager li{
	padding: 0 !important;
}
.pager li.pager-next a{
	background: url("images/slider_arrows.png") no-repeat scroll right center transparent;
	right: 0;
}
.pager li.pager-previous a{
	background: url("images/slider_arrows.png") no-repeat scroll 0 center transparent;
	left: 0;
}
.pager li.pager-next a:hover,
.pager li.pager-previous a:hover {
	opacity: 0.8;
}
.pager .pager-last,
.pager .pager-first {
	display: none !important; 
}
.item-list.pager_my_ui .pager-current {
    background: none;
    border: none;
}
.pager li.pager-current {
	color: $green;
	font-weight: 700;
	font-size: 18px;
	text-decoration: underline;
}
/*Loader*/
.ajax-progress {
	background: rgba(255,255,255,.8);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-indent: -9999px;
	margin: 0;
	padding: 0;
	z-index: 1000;
	.throbber{
	    background: none;
	    border-radius: 6px;
	    position: fixed;
	    left: 50%;
	    top: 50%;
	    width:50px;
	    height:50px;
	    text-indent: -9999px;
	    margin: 0;
	    padding: 0;
	    border: 8px solid rgba(0,0,0,.3);
	    border-left: 8px solid #000;
	    border-radius: 100%;
	    -webkit-animation: rotate 0.7s linear infinite;
	    margin-top: -25px;
	    margin-left: -25px;
	}
}
@-webkit-keyframes rotate{
	0%{
		-webkit-transform:rotate(0deg);
	}
	100%{
		-webkit-transform:rotate(360deg);
	}
}

@-webkit-keyframes fadeOut{
	0%{
		-webkit-transform:scale(0);
	}
	50%{
		-webkit-transform:scale(1);opacity: 0.1;
	}
	100%{
		-webkit-transform:scale(.1);opacity: 0.8;
	}
}
@-webkit-keyframes strechdelay{
	0%,50%,100%{
		-webkit-transform:scaleY(.4);
	}
	20%{
		-webkit-transform:scaleY(1);
	}
}
/*Loader*/
.page-user #middle {
	margin: 0 auto;
	text-align: center;
	display: table;
}
#tabs-wrapper{
	background: rgba(0,0,0,0.6);
	position: fixed;
	bottom: 10px;
	left: 10px;
	z-index: 95;
}
.tabs {
	border-bottom: 0 !important;
}
.tabs a {
	font-size: 16px;
	color: #fff !important;
	background: none !important;
	border: none !important;
}
.tabs a.active,
.tabs a:hover {
	color: rgb(100, 195, 0) !important;
}
.page-user #block-system-main form{
	display: table;
	margin: 0 auto;
}
.page-user #block-system-main .description {
	display: none;
}
.page-user #block-system-main label {
	font-size: 16px;
	color: #000;
	margin-top: 10px;
}
.page-user #block-system-main input {
	width: 230px;
	height: 33px;
	border: 1px solid #dededf;
	padding: 0 10px;
	color: #8a8989;
	font-size: 14px;
}
.page-user #block-system-main .form-submit {
	@include btn;
	background: none;
	border-radius: none !important;
	margin-top: 20px;
}
.page-error-403 .clearfix,
.page-error-404 .clearfix,
.maintenance-page .clearfix {
	text-align: center;
	color: #514e4e;
	font-size: 16px;
}
.page-user .footer,
.page-error-403 .footer,
.page-error-404 .footer,
.maintenance-page .footer{
	margin-top: -125px;
}
.maintenance-page{
	text-align: center;
}
.maintenance-page #container{
	overflow: hidden;
}
.maintenance-page .logo-floater {
	margin: 0 auto;
	padding-top: 15px;
	display: table;
} 
.popup_fon {
    position: fixed;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    display: none;
    z-index: 99;
}
#gl_devel{
	position: absolute;
	right: 50%;
	margin-right: -555px;
	bottom: 25px; 
	color: #b5b8bb;
}
#gl_devel span,
#gl_devel a{
	font:12px/20px 'cuprumregular';
	text-transform:uppercase;
}
#gl_devel span{
	color:#b5b8bb;
}
#gl_devel img{
    margin-right: 5px;
    position: relative;
    top: 3px;
}
#gl_devel a{
	color:#b5b8bb;
	text-decoration:none;
}
body.ie_browser #gl_devel a,
body.ie_browser #gl_devel span{
	font-size:8px;
}
#gl_devel a:hover{
	text-decoration:underline;
}
.fixed_mobile{
	display: none;
}
.webform-confirmation{
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	padding: 0 10px;
}
.slicknav_menu{
	display: none;
}
#block-drupal-package-block-about-front .field_inner_wrap_field_form_title::before{
	top: 24px;
}
@media (max-width:767px){
	#block-block-2 .block_content .term__wrap{
		display: none;
	}
	#block-block-2 .block_content .term__wrap.active{
		display: block;
	}
	#block-block-2 .block_content .term__wrap.mobile{
		display: block;
	}
	.slicknav_menu{
		display: block;
		background: $green;
	}
	.middle{
		padding: 15px!important;
		overflow: hidden;
	}
	.slicknav_btn{
		background: #068249;
	}
	#block-block-2{
		width: 100%;
	}
	.slicknav_nav a{
		font-size: 16px;
	}
	.slicknav_nav a.active{
		font-weight: 700;
		color: #fff;
	}
	.fixed_mobile {
	    position: fixed;
	    left: 0;
	    bottom: 0px;
	    z-index: 90;
	    width: 100%;
	    text-align: center;
	    background: #fff;
	    box-shadow: 0 0px 10px 0 rgba(0,0,0,0.2);
	    font-size: 20px;
	    min-width: 320px;
	    -webkit-transform: translateZ(0);
	    display: block;
	}
	.fixed_mobile a {
	    display: block;
	}
	.fixed_mobile a span {
	    display: inline-block;
	    vertical-align: top;
	    padding: 10px 0 10px 30px;
	    background: url(images/ico_phone.png) no-repeat scroll 0 center transparent;
	    cursor: pointer;
	}
	body{
		background: none;
	}
	.header{
		padding-top: 0;
	}
	.header_wrap .logo_floater{
		float: none;
		text-align: center;
		padding-bottom: 5px;
	}
	.header_wrap .phone_wrap .field_inner_wrap_field_email,
	.header_wrap .phone_wrap .field_inner_wrap_field_header_phone,
	.header_wrap .phone_wrap{
		float: none;
		text-align: center;
	}
	.header_wrap .phone_wrap .field_inner_wrap_field_header_phone .field-item{
		float: none;
	}
	.header_wrap .phone_wrap .field_inner_wrap_field_header_phone .field-item a{
		display: inline-block;
	}
	.header_wrap .phone_wrap .field_inner_wrap_field_header_phone .field-item a{
		padding-left: 23px;
		background-position: 0 0 ;
	}
	.header_wrap .field_inner_wrap_field_slogan{
		padding: 15px 0;
	}
	#block-drupal-package-block-slider-front{
		display: none;
	}
	#block-views-products-tax-block-2 .views-row{
		width: 50%;
	}
	#block-views-products-tax-block-2 .group_item:not(:last-child){
		border: none;
	}
	#block-drupal-package-block-our-products .block_wrap{
		width: 100%;
		float: none;
	}
	.view-products.view-display-id-block_1 .group_item,
	.view-products.view-display-id-block_3 .group_item{
		float: none;
	}
	.view-products.view-display-id-block_1 .views-row,
	.view-products.view-display-id-block_3 .views-row{
		width: 50%;
		text-align: center;    
		min-height: 185px;
	}
	.view-products.view-display-id-block_1 .views-row .views-field-title a,
	.view-products.view-display-id-block_3 .views-row .views-field-title a{
		text-align: center;
	}
	.view-products.view-display-id-block_1 .views-row .views-field-field-products-image .field-content, .view-products.view-display-id-block_2 .views-row .views-field-field-products-image .field-content, .view-products.view-display-id-block_3 .views-row .views-field-field-products-image .field-content{
		width: 100%;
	}
	#block-drupal-package-block-about-front .field_inner_wrap_field_about_coll{
		display: none;
	}
	#block-views-products-tax-block-2::before{
		display: none;
	}
	#block-drupal-package-block-our-products{
	    padding: 0 15px 0 15px;
	   margin-bottom: 0;
	}
	#block-drupal-package-block-about-front{
		padding: 0 15px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	#block-drupal-package-block-about-front .field_inner_wrap_field_gallery_video iframe{
		width: 100% !important;
		height: 225px !important;
	}
	#block-views-article-block-2{
		width: 100%;
	}
	#block-views-article-block-2 .view-content .views-row{
		width: 100%;
		margin-right: 0;
		text-align: center;
		margin-bottom: 20px;
	}
	#block-views-article-block-2 .view-content .views-row .views-field-title::after{
		left: 50%;
		margin-left: -22px;
	}
	#block-views-article-block-2{
		padding: 15px;
		margin-bottom: 0;
	}
	#block-views-article-block-3{
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 0;
	}
	.footer .foot_wrap{
	    background: #1A723A;
	    padding-left: 15px;
	    padding-right: 15px;
	    padding-bottom: 85px;
	}
	.footer{
		height: auto;
		margin-bottom: 0;
	}
	.footer .logo_floater,
	.footer .field_inner_wrap_field_footer_phones{
		float: none;
		text-align: center;
	}
	.footer .field_inner_wrap_field_footer_phones .field-item{
		float: none;
		display: inline-block;
		margin: 5px 10px 5px 0 ;
	}
	.footer .field_inner_wrap_field_footer_phones .field-item a{
		padding-left: 23px;
		background-position: 0 center ;
	}
	.footer .block-system-footer-menu .group_item{
		padding-right: 20px;
	}
	.footer .block-system-footer-menu{
		width: 100%;
		padding-top: 10px;
		display: none;
	}
	#gl_devel{
		margin-right: 0;
		-webkit-transform:translateX(50%);
		transform:translateX(50%);
		bottom: 57px;
	}
	#block-drupal-package-block-about-front .field_inner_wrap_field_description {
		text-align: justify;
	}
	#block-drupal-package-block-our-products .block_wrap .field_inner_wrap_field_form_title,
	#block-drupal-package-block-about-front .field_inner_wrap_field_form_title{
		text-align: center;
	}
	#block-drupal-package-block-our-products .block_wrap .field_inner_wrap_field_description{
		text-align: justify;
	}
	#block-drupal-package-block-our-products .field_inner_wrap_field_link .field-item a{
		display: table;
		margin: 0 auto;
	}
	#block-views-products-tax-block-2{
		padding: 0 5px;
	}
	.header_wrap .field_inner_wrap_field_slogan{
		font-size: 22px;
	}
	#block-system-main-menu{
		display: none;
	}
	.block::before,
	#block-views-products-tax-block-2:before,
	#block-drupal-package-block-about-front:before,
	#block-views-article-block-3::before,
	.middle::before{
		display: none !important;
	}
	.page-taxonomy-term #block-system-main, .node-type-products #block-system-main{
		width: 100%;
	}
	.view-products-tax.view-display-id-block_1 .views-field-name{
		text-align: center;
	}
	.view-products-tax.view-display-id-block_1 .views-field-field-products-img{
		float: none;
		margin: 0 0 10px 0;
	}
	.pager{
		margin-top: 15px !important;
	}
	.node-products .images_wrap{
		width: 100%;
		float: none;
	}
	.node-products .images_wrap .images_wrap-min .image{
		margin: 0 20px;
	}
	.node-products .images_wrap button.slick-next{
		right: -15px;
	}
	.node-products .images_wrap button.slick-prev{
		left: -15px;
	}
	.node-products .form_block .package_block,
	.node-products .field-name-field-products-coll .field-label{
		width: 100%;
		float: none;
	}
	.node-products .form_block form{
		width: 100%;
	}
	.node-products .form_block form label{
		float: none;
		text-align: left;
	}
	.node-products .form_block form .form-item .form-text{
		width: 100%;	
		height: 30px;
	}
	.node-products .order_btn,
	.node-products .form_block form .form-submit{
	    display: table;
    	margin: 0 auto;
	}
	.node-products .form_block form .form-item textarea{
		width: 100%;
		margin-left: 0;
	}
	.view-article.view-display-id-page_1 .views-row, .view-article.view-display-id-block_1 .views-row, .view-article.view-display-id-page_2 .views-row{
		text-align: center;
		width: 100%;
		margin-bottom: 20px;
	}
	.view-article.view-display-id-page_1 .views-row .views-field-title::after, .view-article.view-display-id-block_1 .views-row .views-field-title::after, .view-article.view-display-id-page_2 .views-row .views-field-title::after{
		left: 50%;
		margin-left: -22px;
	}
	.view-article.view-display-id-page_2 .views-row .views-field-body,
	.view-article.view-display-id-page_1 .views-row .views-field-body{
		text-align: justify;
	}
	.my_bread{
		display: none;
	}
	.page_title{
		text-align: center;
		font-weight: 400;
	}
	.node-article .field-name-body p:first-child, .node-news .field-name-body p:first-child{
		font-size: 16px;
	}
	.view-documents.view-display-id-page_1 .views_row{
	    width: 100%;
	    text-align: center;
	    margin-bottom: 15px;
	    padding: 0 25px;
	}
	.view-documents.view-display-id-page_1 .group_item{
		float: none;
		border: none;
		margin-bottom: 0;
	}
	.view-documents.view-display-id-page_1 .views_row .file_type{
		float: none;
		margin: 0 auto 10px;
	}
	.view-documents.view-display-id-page_1 .views_row .file_size{
		padding-bottom: 0;
	}
	.node-gallery .images_wrap{
		width: 100%;
	}
	.node-gallery .images_wrap .images_min{
		width: 100%;
	}
	.node-gallery .images_wrap .images_max{
		height: 220px;
	}
	.node-gallery .images_wrap .images_min img{
		margin: 0 auto;
	}
	.node-gallery .images_wrap .images_min button.slick-next{
		right: 0;
	}
	.node-gallery .images_wrap .images_min button.slick-prev{
		left: 0;
	}
	#node-12 iframe{
		width: 100%;
		height: 250px;
	}
	#block-drupal-package-block-about .field_inner_wrap_field_about_coll{
		display: none;
	}
	#block-drupal-package-block-contacts .block_wrap{
		float: none;
		width: 100%;
		margin-bottom: 10px;
		overflow: hidden;
	}
	#block-drupal-package-block-contacts .form_wrap{
		float: none;
		width: 100%;
		clear: both;
		text-align: center;
	}
	#block-drupal-package-block-contacts .form_wrap form .form-text{
		height: 30px;
	}
	#block-drupal-package-block-contacts .block_wrap > .field_inner_wrap:not(:first-child) {
	    float: left;
	    width: 100%;
	    text-align: center;
	    margin-bottom: 20px;
	}
	.field_inner_wrap_field_form_title::before{
		display: none !important;
	}
	#block-drupal-package-block-about-front .field_inner_wrap_field_description p:first-child{
		font-size: 16px;
	}
	.middle{
		margin-bottom: 0;
	}
	.node-gallery .images_wrap .images_max iframe{
		height: 220px;
	}
}
@media (min-width:768px) and (max-width:991px){
	#block-drupal-package-block-about-front{
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding-right: 30px;
		padding-bottom: 30px;
	}
	#block-drupal-package-block-slider-front .block_content .field-name-field-image{
		margin-right: 10px;
		width: 320px;
	}
	#block-drupal-package-block-slider-front .block_content .slick-dots{
		bottom: -30px;
	}
	#block-views-products-tax-block-2 .views-row{
		padding-right: 10px;
		padding-left: 10px;
	}
	#block-views-products-tax-block-2 .views-row .views-field-name a{
		font-size: 12px;
	}
	#block-drupal-package-block-our-products .block_wrap{
		width: 185px;
	}
	#block-drupal-package-block-about-front .field_inner_wrap_field_about_coll{
		display: none;
	}
	#gl_devel{
		margin-left: -355px;
	}
	#block-views-article-block-2{
		width: 485px;
		padding: 15px;
	}
	#block-views-article-block-3{
		width: 265px;
		padding-right: 15px;
		padding-left: 15px;
	}
	#block-views-article-block-3 .field_inner_wrap_field_form_title::before{
		display: none;
	}
	.footer .logo_floater{
		width: 145px;
	}
	.footer .block-system-footer-menu .group_item{
		padding-right: 20px;
	}
	.footer .block-system-footer-menu{
		width: 335px;
	}
	.footer .field_inner_wrap_field_footer_phones{
		width: 185px;
	}
	.header_wrap .logo_floater{
		width: 190px;
		padding-top: 15px;
	}
	.header_wrap .phone_wrap{
		width: 185px;
		padding-bottom: 15px;
	}
	.header_wrap .field_inner_wrap_field_slogan{
		padding-top: 15px;
	}
	#block-block-2 .block_content .term__name .field_content a{
		padding-left: 60px;
	}
	#block-block-2 .block_content .term__name img{
		left: 10px;
	}
	#block-block-2{
		width: 251px;
	}
	.page-taxonomy-term #block-system-main, .node-type-products #block-system-main{
		width: 465px;
	}
	.view-products-tax.view-display-id-block_1 .views-field-field-products-img{
		float: none;
		margin-right: 0;
		text-align: center;
		margin-bottom: 15px;
	}
	#block-block-2 .block_content .child_wrap a{
		word-break: break-all;
	}
	.node-products .images_wrap{
		width: 480px;
		float: none;
		margin-right: 0;
	}
	.node-products .field-name-field-products-coll .field-label{
		float: none;
	}
	.node-products .form_block .package_block{
		padding-bottom: 20px;
	}
	.node-gallery .images_wrap{
		width: 710px;
	}
	.node-gallery .images_wrap .images_min{
		width: 600px;
	}
	#block-system-main-menu .menu li a{
		font-size: 15px;
	}
	#block-drupal-package-block-contacts .form_wrap{
		width: 200px;
	}
	#block-drupal-package-block-contacts .block_wrap{
		width: 485px;
	}
	.my_bread{
		padding-left: 0;
	}
}
@media (min-width:992px) and (max-width:1200px){
	#block-drupal-package-block-slider-front .block_content .field-name-field-form-title{
		padding-top: 25px;
	}
	#block-drupal-package-block-about-front .field_inner_wrap_field_description p:first-child{
		font-size: 16px;
	}
	#block-drupal-package-block-about-front .field_inner_wrap_field_gallery_video iframe{
		width: 100% !important;
		height: 275px !important;
	}
	#block-views-article-block-2{
		width: 720px;
	}
	#block-drupal-package-block-about-front{
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	#gl_devel{
		margin-right: -465px;
	}
	.footer .field_inner_wrap_field_footer_phones{
		width: 200px;
	}
	#block-block-2{
		width: 255px;
	}
	.page-taxonomy-term #block-system-main, .node-type-products #block-system-main{
		width: 720px;
	}
	#block-block-2 .block_content .child_wrap a{
		word-break: break-all;
	}
	.node-products .images_wrap{
		width: 480px;
	}
	.node-products .form_block .package_block{
		width: 195px;
	}
	#block-drupal-package-block-contacts .block_wrap{
		width: 690px;
	}
}
.table-for-kompressor p{
	    margin: 0 0 5px 0!important;
}
.table-for-kompressor, .table-for-kompressor td, .table-for-kompressor th {
    border: 1px solid #8D8D8D;
    padding: 3px;
}
.table-title {
    color: #ffffff;
    background-color: #7F7F7F;
    font-weight: bold;
    height: 30px;
}
.table-for-kompressor {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    vertical-align: middle;
    background-color: #ffffff;
}
.field-name-field-products-table{
	clear: both;
}